@use './variables.scss';

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-color);
  color: var(--color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a {
  color: var(--link-color);
  &:hover {
    text-decoration: underline;
  }
}

h1 {
  font-size: 1.75rem;
  margin: 0.75rem 0;
}

h2 {
  font-size: 1.5rem;
  margin: 0.75rem 0;
}
h3, h4, h5, h6 { font-style: 1.24rem; margin-bottom: 0.5rem; }

p { margin: 0.5rem 0; }

.app {
    height: 100vh;
    display: grid;
    grid-template: auto 1fr auto / 1fr;
    grid-template-areas:
        'header'
        'main'
        'footer'
    ;
}

.app_header {
    grid-area: header;
    background: var(--header-color);

    &_content {
      display: grid;
      // grid-template: 1fr / 100px auto;
      // grid-template-areas: 'logo navigation';
      align-items: center;
    }

    &_logo {
        // grid-area: logo;
        width: 50px;
        height: 50px;
        fill: currentColor;
    }
}

.app_navigation {
  // grid-area: navigation;
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 5rem;
  gap: 2rem;
  &_button {
    --btn-color: rgba(255,255,255,.2);
    padding: 0.5rem;
    position: relative;
    isolation: isolate;

    &::after,
    &::before {
      content: '';
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    &::before {
      background: var(--btn-color);
      transform: skew(5deg) translate(-5px, 5px);
      z-index: -2;
      opacity: 0.5;
    }
    &::after {
      background: var(--btn-color);
      transform: skew(5deg);
      z-index: -1;
    }
  }
  // & a {
  //   // flex: 1;
  //   // padding: .5rem 1rem;
  // }
}

.app_main {
    grid-area: main;
    background: var(--main-color);
}

.app_footer {
    grid-area: footer;
    background: var(--footer-color);
    padding: 20px 0;
}

.app_content {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: 1000px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
}

.app_page {
  &-contacts {
    dl {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: .5rem;
    }
    dt {
      font-weight: bold;
      &::after {
        content: ":";
      } }
    // dd {}

  }
  &-about {
    ul {
      li {
        &:before {
          content: "–";
          padding-right: 10px;
        }
      }
    }
  }
}

/// helper classes

.crop {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.d-ib {
  display: inline-block;
}
.w-max200 {
  max-width: 200px;
}