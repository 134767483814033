:root {
    color-scheme: light dark;

    --background-color: rgb(230, 230, 230);
    --color: rgb(5, 5, 5);
    --link-color: #33606d;
    --header-color: rgba(173, 216, 230, .3);
    --footer-color: var(--header-color);
    // --footer-color: rgba(144, 238, 144, .3);
    --main-color: rgba(250, 250, 210, .0);

    @media (prefers-color-scheme: dark) {
        --background-color: #282c34;
        --color: rgba(240, 255, 255, 1);
        --link-color: rgb(97, 218, 251);
    }
}
